<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('lookupHistoryDetailsMenu') }}</h3>
  </div>
  <paginated-table-wrapper v-if="license" :sort="'desc'" :license="license" :request="getLookupHistoryRequest"
    :csv-handler="downloadCsvHandler" :is-search="false" v-slot="{ list, isLoading }">
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table :list="list" @retry="retryHandler"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { getLookupBulkHistory, postWebhookHistoryRetryApi } from '../../services/statistic.js'
import { store } from '@/application/store'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formattedCSVLookupBulkHistory } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LookupBulkHistoryPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/LookupBulkHistoryTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const updatedItems = ref([])
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getLookupHistoryRequest = getLookupBulkHistory
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'lookup-bulk-history.csv',
      formattedCSVLookupBulkHistory,
      {
        id: i18n.t('id'),
        count: i18n.t('contact_count'),
        completed: i18n.t('completed'),
        date: i18n.t('date'),
      }
    )

    const getActualListHandler = (list) => {
      if (updatedItems.value.length === 0) {
        return list
      }

      const data = updatedItems.value.pop()
      const index = list.findIndex(item => item.id === data.id)
      list.splice(index, 1, data)
      return list
    }

    const retryHandler = (id) => postWebhookHistoryRetryApi(id, { Authorization: license.value.license_key })
      .then(({ data }) => {
        updatedItems.value.push(data)
      })
      .catch(({ err }) => {
        console.log(err)
      })

    return {
      license,
      getLookupHistoryRequest,
      downloadCsvHandler,
      retryHandler,
      getActualListHandler,
    }
  },
}
</script>
